import * as signalR from "@microsoft/signalr";
import { SUBSCRIBE } from "./hubEvents";
import { ENV } from "../config/config";
import { Environment } from "../config/EnviormentEnum";

let connection = null;

export const createSignalRConnection = (url, token) => {
  connection = new signalR.HubConnectionBuilder()
    .withUrl(url, {
        accessTokenFactory: () => token
    })
    .withAutomaticReconnect()
    .configureLogging(ENV === Environment.DEVELOP ? signalR.LogLevel.Information : signalR.LogLevel.Error)
    .build();
};

export const getConnection = () => {
    return connection;
};

export const connectSignalR = async () => {
  if (connection) {
    await connection.start();
  }
};

export const subscribeToEvents = async (id) => {
  if (connection) {
     await connection.invoke(SUBSCRIBE, id);
  }
};

export const disconnectSignalR = async () => {
    if (connection) {
      await connection.stop();
    }
};