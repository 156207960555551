import React from 'react'
import Plot from "react-plotly.js";

const BarChartInstrument = ({title, range, data, x_axis_names}) => {
  return (
    <Plot
    data={[
        {
            x: x_axis_names,
            y: data,
            type: 'bar',
            text: data?.map(String),
            textposition: 'auto',    
        }
    ]}
    layout={{ 
        autosize: true, 
        'yaxis.range': range, 
        title: { text: title }, 
        paper_bgcolor:'rgba(0,0,0,0)',
        font:{color: "white"},
        plot_bgcolor:'rgba(0,0,0,0)',
        colorway : ['white'], 
        margin:{
          l:30,
          r:30,
          t:80,
          b:50
        }
        // shapes: [
        //   {
        //     type: 'line',
        //     x0: 0,
        //     y0: 150,
        //     x1: 1,
        //     y1: 150,
        //     xref: 'paper',
        //     yref: 'y',
        //     line: {
        //       color: 'red',
        //       width: 2,
        //     }
        //   }
        // ]
    }}
    config={{ 
      displayModeBar: false, 
      responsive: true, 
      staticPlot: true 
    }}
  />
  )
}

export default BarChartInstrument
