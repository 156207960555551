import { FETCH_SYSTEMS, 
    FETCH_SYSTEMS_ERROR, 
    FETCH_SYSTEMS_SUCCESS 
} from "../actions/action.types";

const systems = (
    state = {
        data: [],
        isLoading: false,
        error: null
    },
    action = {}
) => {
    switch (action.type) {
        case FETCH_SYSTEMS: {
            return { ...state,
                isLoading: true
            };
        }
        case FETCH_SYSTEMS_SUCCESS: {
            return { ...state, 
                isLoading: false,
                data: action.data
            };
        }
        case FETCH_SYSTEMS_ERROR: {
            return { ...state, 
                isLoading: false,
                error: action.error,
                data: []
            };
        }
        default:
            return state;
    }
}

export default systems;