import React from 'react'
import Plot from "react-plotly.js";

const GaugeInstrument = ({data, reference, range, title}) => {
  return (
    <Plot
      data={[
        {
          domain: { x: [0, 1], y: [0, 1] },
          value: data,
          title: { text: title },
          type: "indicator",
          mode: "gauge+number+delta",
          delta: { reference: reference },
          gauge: { 
                  axis: { range: range }, 
                  bar: {color: 'green'}, 
                  steps: [
                    {range: [0, 0.45], color: 'lightgray'},
                    {range: [0.45, 0.6], color: 'gray'}],
                  threshold: {
                    line: {color: "red", width: 4},
                    thickness: 0.75,
                    value: 0.5}
          }
        }
      ]}
      layout={{
        autosize: true, 
        fixedrange: true, 
        paper_bgcolor:'rgba(0,0,0,0)', 
        font:{color: "white"},
        // width: '400px',
        // height: '400px',
        margin:{
          l:30,
          r:30,
          t:80,
          b:30
        }
      }}
      config={{ 
        displayModeBar: false, 
        responsive: true, 
        staticPlot: true 
      }}
    />
  )
}

export default GaugeInstrument
