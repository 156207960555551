import { takeLatest, takeEvery, take, cancel, call, put, fork, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { 
  hubConnectSuccess, 
  hubConnectFailure, 
  hubSubscribeSuccess, 
  hubSubscribeFailure,
  hubReceiveData 
} from '../actions/actions.hub';
import { 
    HUB_CONNECT, 
    HUB_DISCONNECT, 
    HUB_SUBSCRIBE
} from '../actions/action.types'
import { createSignalRConnection, connectSignalR, subscribeToEvents, disconnectSignalR, getConnection } from '../signalrHub/signalrHub';
import { logOut } from '../actions/action.auth';
import { HUB_URL } from '../config/config';
import { RECIEVE_DATA } from '../signalrHub/hubEvents';


const createSignalREventChannel = (connection) => {
    return eventChannel((emit) => {
      const handler = (data) => {
        emit(data); 
      };
  
      connection.on(RECIEVE_DATA, handler);
  
      // The subscriber must return an unsubscribe function
      return () => {
        connection.off(RECIEVE_DATA, handler); // Unsubscribe when channel is closed
      };
    });
};

function* handleReceiveData(data) {
    yield put(hubReceiveData(data)); // Dispatch received data to the store
}

function* watchSignalREvents(channel) {
    yield takeEvery(channel, handleReceiveData); // Handle each event from the channel
}

function* connectSaga(action) {
  try {
    const auth = yield select(state => state.auth);
    createSignalRConnection(HUB_URL + '/legacy', auth.token);
    
    yield call(connectSignalR);
    yield put(hubConnectSuccess());
    
    // Automatically trigger subscription after successful connection
    yield put({ type: HUB_SUBSCRIBE, id: action.id });
    
  } catch (error) {
    yield put(hubConnectFailure("An error has occured. Please contact your administrator."));
  }
}

function* subscribeSaga(action) {
    let channel;
    try {
        yield call(subscribeToEvents, action.id);
        yield put(hubSubscribeSuccess());

        const connection = getConnection();
        channel = yield call(createSignalREventChannel, connection);

        // Fork a task to watch the events
        const task = yield fork(watchSignalREvents, channel);

        // Wait for the unsubscribe action
        yield take(HUB_DISCONNECT);
        yield cancel(task); // Cancel the event watching task
        channel.close();    // Close the channel to clean up
        yield call(disconnectSignalR); // Disconnect when unsubscribing

    } catch (error) {
        if (error.message.includes('unauthorized')) {
          yield put(hubSubscribeFailure(error.message));
          yield call(disconnectSignalR);
          yield put(logOut());
        } else {
          yield put(hubSubscribeFailure("An error has occured. Please contact your administrator."));
        }
    }
}

export function* hubWatcher() {
    yield takeLatest(HUB_CONNECT, connectSaga);
    yield takeLatest(HUB_SUBSCRIBE, subscribeSaga);
}

export function* hubSaga() {
    yield fork(hubWatcher);
}