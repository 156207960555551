import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import reducer from "../reducers";
import axios from 'axios';
import createFetchMiddleware from './fetchMiddleware'
import { ENV } from '../config/config';
import { Environment } from '../config/EnviormentEnum';

// Create the saga middleware
export default () => {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      http: axios,
    },
  });
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        // thunk: {
        //   extraArgument: {
        //     history: services.history,
        //     http: services.http,
        //     appConfig: services.appConfig,
        //   },
        // },
      })
        .concat(createFetchMiddleware({ http: axios }))
        .concat(sagaMiddleware),
        //.concat(baseApi.middleware),
    devTools: ENV === Environment.DEVELOP ? true : false,
  });
  sagaMiddleware.run(rootSaga);
  return store;
};
