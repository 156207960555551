import React from 'react'
import { fetchSystems } from '../actions/action.systems';
import { changeNavbarTitle } from '../actions/action.navbar';
import { useDispatch, useSelector } from 'react-redux';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
//import GasMeterOutlinedIcon from '@mui/icons-material/GasMeterOutlined';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeader from '../components/PageHeader'
import DropdownList from '../components/DropdownList'
import { Box, CircularProgress } from '@mui/material'
import { List, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SystemsView = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch =  useDispatch();
    const systems = useSelector(state => state.systems);
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(fetchSystems());
        dispatch(changeNavbarTitle("SYSTEMS"));
    }, [dispatch]);

    return (
        <Box sx={{ width: '100%' }}>
        <PageHeader title={'Your Systems'} />
        <Box sx={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', m: 2 }}>
          {!systems.isLoading ? 
          systems.data.map((system) => (
            <Box sx={{ my: 2, mx: isMobile ? 0: 2 }} key={system.id}>
              <DropdownList title={system.name} subtitle={system.description} icon={<ViewInArOutlinedIcon sx={{ fontSize: 80 }}/>}>
                <List component='ul' disablePadding sx={{ color: 'primary.main', }}>
                    {/* <Box sx={{ p: 2, backgroundColor: 'secondary.main'  }}>
                      <Typography variant='h4'>Additional Info</Typography>
                      <Box sx={{ m: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography>Phone: {client.phone}</Typography>
                        <Typography>Email: {client.email}</Typography>
                      </Box>
                    </Box> */}
                    <ListItemButton sx={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }} onClick={(event) => {
                      event.preventDefault();
                      navigate(`/realtime/${system.id}`);
                    }}>
                        <ListItemText primary='Real Time View' />
                    </ListItemButton>
                    {/* <ListItemButton>
                        <ListItemText primary='Edit' />
                    </ListItemButton> */}
                </List> 
              </DropdownList>
            </Box> 
          )) : <CircularProgress sx={{ justifySelf: isMobile ? 'center' : 'end' }}/>}
        </Box>
    </Box>
    )
}

export default SystemsView
