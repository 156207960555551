import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, Navigate, Outlet } from 'react-router-dom';

const EnsureAdminAccess = () => {
    const user = useSelector(state => state.auth.user);
    const location = useLocation();

  return (
    user && !user.isClient ?
    <Outlet />
    : <Navigate to="/" state={{from: location}} replace />
  )
}

export default EnsureAdminAccess
