import { Box, CircularProgress, Typography } from '@mui/material'
import { List, ListItemButton, ListItemText } from '@mui/material';

import React from 'react'
import DropdownList from '../components/DropdownList'
import PageHeader from '../components/PageHeader'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { fetchClients } from '../actions/action.clients';
import { useDispatch, useSelector } from 'react-redux';
import { changeNavbarTitle } from '../actions/action.navbar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const ClientsView = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients);

  React.useEffect(() => {
    dispatch(fetchClients());
    dispatch(changeNavbarTitle("CLIENTS"));
  }, [dispatch]);

  return (
    <Box sx={{ width: '100%' }}>
        <PageHeader title={'Clients'} />
        <Box sx={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', m: 2 }}>
          {!clients.isLoading ? 
          clients.data.map((client) => (
            <Box sx={{ my: 2, mx: isMobile ? 0: 2 }} key={client.id}>
              <DropdownList title={client.name} subtitle={client.location} icon={<PersonOutlineIcon sx={{ fontSize: 80 }}/>}>
                <List component='ul' disablePadding sx={{ color: 'primary.main', }}>
                    <Box sx={{ p: 2, backgroundColor: 'secondary.main'  }}>
                      <Typography variant='h4'>Additional Info</Typography>
                      <Box sx={{ m: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography>Phone: {client.phone}</Typography>
                        <Typography>Email: {client.email}</Typography>
                      </Box>
                    </Box>
                    <ListItemButton>
                        <ListItemText primary='Systems' />
                    </ListItemButton>
                    <ListItemButton sx={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}>
                        <ListItemText primary='Edit' />
                    </ListItemButton>
                </List> 
              </DropdownList>
            </Box> 
          )) : <CircularProgress sx={{ justifySelf: isMobile ? 'center' : 'end' }}/>}
        </Box>
    </Box>
  )
}

export default ClientsView
