import { call, fork, put, takeLeading, getContext } from "redux-saga/effects";
import { LOGIN, LOGIN_ERROR, LOGIN_SUCCESS, SET_CREDENTIALS } from "../actions/action.types";
import { API_URL } from '../config/config';

function* loginWatcher() {
    yield takeLeading(LOGIN, loginSaga)
}

function* loginSaga({ username, password }) {
    try {
        const http = yield getContext("http");
        const { data } = yield call(
            http.post,
            `${API_URL}/auth`,
            {
                username,
                password
            }
        );
        yield put({ type: SET_CREDENTIALS, token: data.token, user: data.user });
        yield put({ type: LOGIN_SUCCESS });
    } catch(err) {
        if (!err.response) {
            yield put({ type: LOGIN_ERROR, error: "A problem occured. Please contact your administrator." });
        } else {
            if (err.response.status === 401) {
                yield put({ type: LOGIN_ERROR, error: err.response.data });
            }
            else {
                yield put({ type: LOGIN_ERROR, error: "A problem occured. Please contact your administrator."  });
            }
        }
    }
    

}

export function* authSaga() {
    yield fork(loginWatcher);
}