import React, { useEffect } from 'react'
import GaugeInstrument from '../components/instruments/GaugeInstrument'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hubConnect, hubDisconnect } from '../actions/actions.hub';
import BarChartInstrument from '../components/instruments/BarChartInstrument';
import { Box, Typography } from '@mui/material';
import LampIndicatorInstrument from '../components/instruments/LampIndicatorInstrument';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './RealTimeSystemView.css';

const x_axis_names = ['Cell 1', 'Cell 2', 'Cell 3', 'Cell 4', 'Cell 5', 'Cell 6'];
const currentDefaultValues = [200, 200, 200, 200, 200, 200];
const voltageDefaultValues = [24, 24, 24, 24, 24, 24];
const temperatureDefaultValues = [50, 50, 50];

const RealTimeSystemView = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { id } = useParams();
  const dispatch = useDispatch();
  const { isConnected, data, error } = useSelector(state => state.hub);
  const systems = useSelector(state => state.systems.data);
  const systemName = systems.find(s => s.id === id)?.name;
  useEffect(() => {
    // Connect only if not already connected
    if (!isConnected) {
      dispatch(hubConnect(id));
    }

    // Cleanup function to disconnect SignalR when component unmounts
    return () => {
      if (isConnected) {
        dispatch(hubDisconnect());
      }
    };
  }, [dispatch, isConnected, id]); // Depend on `isConnected`
  
  // Calculate values
  const currentValues = data?.cells.map(c => c.amp.toFixed(2));
  const voltageValues = data?.cells.map(c => c.uin.toFixed(2));
  
  let temperatureValues = [];
  for (let i = 0; i < 3; i++) {
    temperatureValues.push(data?.cells[i].temp.toFixed(2));
  }
  
  const power = ((data?.cells.reduce((acc, curr) => {return acc+=curr.uin*curr.amp}, 0)) / 1000).toFixed(2);

  let waterLevelActive = !!data;

  let waterLevelColor;
  if (data?.state.waterLevelHi) {
    waterLevelColor= 'green';
  } 
  else if (data?.state.waterLevelLo) {
    waterLevelColor= 'yellow';
  } 
  else {
    waterLevelColor = 'red';
  }

  return (
    <>
    {isConnected ?
      <Box sx={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr 1fr' : '1fr 1fr 1fr', gridTemplateRows: !isMobile ? '1fr 1fr' : '1fr 1fr 1fr', height: '100vh', gap: isMobile ? 0 : '20px' }}>
        <GaugeInstrument title={'Pressure'} reference={0} range={[0, 0.6]} data={data?.bar}/>
        <Box sx={{ display:'flex', justifyContent: 'space-between', m: isMobile ? 0 : 2 }}>
          <Box className={'state-lamps'} sx={{ px: isMobile ? '10px' : '20px' }}>
            <LampIndicatorInstrument active={data?.state.valve1} activeColor={'green'} />
            <LampIndicatorInstrument active={data?.state.valve2} activeColor={'green'} />
            <LampIndicatorInstrument active={data?.state.valve3} activeColor={'green'} />
          </Box>
          <Box className={'state-lamps'} sx={{ px: isMobile ? '10px' : '20px' }}>
            <LampIndicatorInstrument active={data?.state.fan1} activeColor={'green'} />
            <LampIndicatorInstrument active={data?.state.fan2} activeColor={'green'} />
          </Box>
          <Box className={'state-lamps'} sx={{ justifyContent: 'space-around', px: isMobile ? '10px' : '20px' }}>
            <Box>
              <Typography variant={'h3'} sx={{ fontWeight: 400, color: 'primary.main' }}>{systemName}</Typography>
            </Box>
            <Box className={'property-container'}>
              <Typography variant={'h3'} sx={{ fontWeight: 400, color: 'primary.main' }}>Power</Typography>
              <Typography variant={'h4'} sx={{ px: 2 }} className='property-value'>{isNaN(power) ? 0 : power} kW</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={'state'} sx={{ m: isMobile ? 0 : 2  }}>
          <Box className={'alarms'}>
            <Typography>В. Н.</Typography>
            <Typography>Н. Н.</Typography>
            <Typography>Вода</Typography>
            <Typography>В. Т.</Typography>
            <LampIndicatorInstrument active={data?.state.highPressureAlarm} activeColor={'red'} />
            <LampIndicatorInstrument active={data?.state.lowPressureAlarm} activeColor={'yellow'} />
            <LampIndicatorInstrument active={data?.state.waterAlarm} activeColor={'green'}/>
            <LampIndicatorInstrument active={data?.state.highTemperatureAlarm} activeColor={'violet'} />
          </Box>
        <Box className="water-container">
          <label className="water-labels">Water Level: <LampIndicatorInstrument active={waterLevelActive} activeColor={waterLevelColor}/> </label>
          <label className="water-labels">Water Pump:  <LampIndicatorInstrument active={data?.state.pump} activeColor={'green'}/> </label>
        </Box>
        </Box>
        <BarChartInstrument title={'Voltage'} range={[0, 30]} data={voltageValues ?? voltageDefaultValues} x_axis_names={x_axis_names}/>
        <BarChartInstrument title={'Current'} range={[0, 400]} data={currentValues ?? currentDefaultValues} x_axis_names={x_axis_names}/>
        <BarChartInstrument title={'Temperature'} range={[0, 100]} data={temperatureValues ?? temperatureDefaultValues} x_axis_names={x_axis_names}/>
      </Box> : 
        <Typography variant={'h1'} sx={{ color: 'primary.main', textAlign: 'center', m: 3 }}>{error ? error : 'Not connected'}</Typography>}
      </>
  );
}

export default RealTimeSystemView
