import { SET_CREDENTIALS, LOGOUT, LOGIN, LOGIN_ERROR, LOGIN_SUCCESS } from "../actions/action.types"

const auth = (
    state = {
        user: null,
        token: null,
        error: null,
        isLoading: false
    },
    action = {}
) => {
    switch(action.type) {
        case SET_CREDENTIALS:
            return {
                ...state,
                user: action.user,
                token: action.token
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
                token: null
            };
        case LOGIN:
            return {
                ...state,
                isLoading: true
            };
        case LOGIN_ERROR:
            return {
                ...state,
                user: null,
                token: null,
                error: action.error,
                isLoading: false
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null
            };
        default:
            return state;
    }
}

export default auth;